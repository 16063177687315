import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ImprintPage = () => (
  <Layout>
    <SEO title="Impressum" />

    <h1>Impressum</h1>

    <p>Lehrerbuch wird angeboten von:</p>

    <p>
      Dominique Klevinghaus
      <br />
      Mittelstr. 25
      <br />
      34576 Homberg
      <br />
      +49 (0)151 5989 8889
      <br />
      info at lehrerbuch.app
      <br />
      <br />
      <span class="greyish">
        UStID gem&auml;&szlig; &sect;27 a Umsatzsteuergesetz: DE254069051
      </span>
    </p>
  </Layout>
)

export default ImprintPage
